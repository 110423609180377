import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
// import Card from "react-bootstrap/Card";

function ImageGrid({ setSelectedImg }) {
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    axios
      .get("/gallery")
      .then((res) => {
        setGallery(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const galleryMarkup = gallery.map((picture) => (
    <motion.div
      className="img-wrap"
      layout
      whileHover={{ opacity: 1 }}
      s
      onClick={() => setSelectedImg(`${picture.imageUrl}`)}
    >
      <motion.img
        src={picture.imageUrl}
        alt="uploaded pic"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
        className="galleryImg"
      />
      <h5 className="text-center mt-4">{picture.studentName}</h5>
    </motion.div>
    // <Card style={{ width: "18rem" }}>
    //   <Card.Img
    //     variant="top"
    //     src={picture.imageUrl}
    //     style={{ height: "250px", objectFit: "cover" }}
    //   />
    //   <Card.Body>
    //     <Card.Title>Name</Card.Title>
    //   </Card.Body>
    // </Card>
  ));

  return <div className="image-grid">{galleryMarkup}</div>;
}

export default ImageGrid;
